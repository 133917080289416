
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ViewChild, OnInit, HostListener, Input, OnChanges, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, NgZone, ElementRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../common/providers/auth.service";
import { ConfigService } from "../common/providers/config.service";
import { HeaderService } from "./header.service";
import { Store } from "@ngrx/store";
import { getUserProfile, getOnlineStatus, RootState, getLoggedInUserContact, getRouteId, getSearchFilters } from "../reducers/index";
import { UserProfile } from "../shared/models";
import { Subject, Subscription } from "rxjs";
import { takeUntil, filter, take, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { CommonUtil } from "../common/utils/common.utils";
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "../common/providers/broadcaster.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { AppConstants } from "../common/utils/app-constants";
import { isArray } from "util";
import { LocalStorageService } from "../common/providers/storage.service";
import { ContactRepository } from "../contacts/repository/contact.repository";
import { AppsDialogComponent } from "../shared/components/apps-dialog/apps-dialog.component";
import { AboutDialogComponent } from "../shared/components/about-dialog/about-dialog.component";
import { MobileAboutDialogComponent } from "../shared/components/mobile-about-dialog/mobile-about-dialog.component";
import { ContactSettingsDialogComponent } from "../shared/components/contact-settings-dialog/contact-settings-dialog.component";
import { AvatarDialogComponent } from "../shared/components/avatar-dialog/avatar-dialog.component";
import { BroadcastKeys } from "../common/enums/broadcast.enum";
import { ContactService } from "../common/service/contact-service";
import { Contact } from "../common/models";
import { ContactListsDialogComponent } from "../shared/components/contact-lists-dialog/contact-lists-dialog.component";
import { MobileActionsDialogComponent } from "../shared/components/mobile-actions-dialog/mobile-actions-dialog.component";
import { ContactTagsDialogComponent } from "../shared/components/contacts-tags-dialog/contact-tags-dialog.component";
import { ContactGroupDialogComponent } from "../shared/components/contact-group-dialog/contact-group-dialog.component";
import { SetSearchType, SetSearchQuery } from "../actions/app";
import { SaveSearchDialogComponent } from "../shared/components/save-search-dialog/save-search-dialog.component";
import { AdvanceSearchComponent } from "../shared/components/advance-search-component/advance-search.component";
import { DatePipe } from "@angular/common";
import { ToastService } from "../common/service/tost.service";
import { ServicedeskDialogComponent } from "../shared/components/servicedesk-dialog/servicedesk-dialog.component";
import { HelpFaqDialogComponent } from "../shared/components/help-faq-dialog/help-faq-dialog.component";

@Component({
  selector: "vp-header",
  templateUrl: "./header.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HeaderComponent implements OnInit, OnDestroy {
  currentUser: UserProfile;
  searchKeyword: string;
  searchMode = false;
  private isAlive$ = new Subject<boolean>();
  showUserMenu: boolean = false;
  profileBackground = "url(" + CommonUtil.getFullUrl("/assets/img/profile-background.jpg") + ")";
  emailOptionExpanded: boolean = false;
  isSearchQueryExist: boolean = false;
  searchText: string = "";
  switchApp: boolean = false;
  currentUrl: string = "";
  isCordovaApp: boolean;
  isCordovaOrElectron: boolean;
  penIcon = CommonUtil.getFullUrl("/assets/img/pen.svg");
  filterIcon = CommonUtil.getFullUrl("/assets/img/filter.svg");
  isMobileScreen: boolean = false;
  appLogo: string = "";
  appLogoDesktop: string = "";
  textLogo: string = "";
  brandName = "VNC";
  private searchKeyDown = new Subject();
  avatarURL: string;
  hideServiceDeskMenu: boolean = false;
  hideFAQMenu: boolean = false;
  hideImprintMenuItem: boolean = true;
  serviceDeskMenuItemName: string = "VNC Service Desk";
  showAdvancedSearchPopover: any;
  isListView: boolean = false;
  isMobileSearch: boolean = false;
  faqURL: string;
  userManual: string;
  serviceDeskURL: string;
  termsOfUse: string;
  dataPrivacy: string;
  imprintLink: string;
  isHandset: boolean;
  displayHeaderAvatar: any;
  quotaMsg: string = "";
  usedQuote: number;
  isHandsetLandscape = false;
  hideProfile: boolean = false;
  browserLang: string = "en";
  URLS: any = {};
  searchType: string = "contact";
  loggedInUser: Contact;
  @Input() url: string;
  contactOptionExpanded: boolean = false;
  routeId: string;
  isSavedSearch: boolean = false;
  saveSearchKeyword: string = "";
  isShowSaveSearchDialog: boolean = false;
  showSavedLbl: boolean = false;
  @ViewChild("searchTextInput", {static: false}) searchTextInput: ElementRef;
  searchModelChanged: Subject<string> = new Subject<string>();
  isOnline: boolean = false;
  ActiveSearchBar: boolean = false;
  isFocused: boolean = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private config: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private store: Store<RootState>,
    private headerService: HeaderService,
    private matDialog: MatDialog,
    private broadcaster: Broadcaster,
    private ngZone: NgZone,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private contactRepository: ContactRepository,
    private contactService: ContactService,
    private activatedRoute: ActivatedRoute,
    private dateFormtter: DatePipe,
    private toastService: ToastService
  ) {
    console.log("[HeaderComponent] constructor", new Date());
    console.log("[Envrionment]: ", environment);
    this.isCordovaApp = environment.isCordova;
    this.isCordovaOrElectron = environment.isCordova || environment.isElectron;
    this.currentUrl = this.router.routerState.snapshot.url;
    this.changeDetectorRef.markForCheck();
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
    this.isHandset = this.breakpointObserver.isMatched([Breakpoints.Handset]);
    this.isHandsetLandscape = this.breakpointObserver.isMatched([Breakpoints.HandsetLandscape]);

    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isHandset = true;
        } else {
          this.isHandset = false;
        }
        this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 766px)");
        this.isHandsetLandscape = this.breakpointObserver.isMatched([Breakpoints.HandsetLandscape]);
        this.changeDetectorRef.markForCheck();
      });
      this.broadcaster.on<any>(BroadcastKeys.RELOAD_USER_PROFILE).pipe(takeUntil(this.isAlive$)).subscribe(res => {
        this.store.select(getUserProfile).pipe(take(1)).subscribe(res => {
          if (!!res && res !== null) {
            this.currentUser = res;
          }
          this.changeDetectorRef.markForCheck();
        });
      });
  }

  ngOnInit() {
    console.log("[HeaderComponent] ngOnInit", new Date());
    this.store.select(getUserProfile).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (!!res && res !== null) {
        console.log("[HeaderComponent][getUserProfile]: ", res);
        this.currentUser = res;
      }
      this.changeDetectorRef.markForCheck();
    });
    this.store.select(getOnlineStatus).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.isOnline = res;
    });

    this.store.select(getLoggedInUserContact).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.loggedInUser = res;
      this.changeDetectorRef.markForCheck();
      console.log("[HeaderComponent][getLoggedInUserContact]", this.loggedInUser);
    });
    this.config.getAllConfig().pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (res.URLS) {
        this.URLS = res.URLS;
        this.changeDetectorRef.markForCheck();
      }
      this.setURLFromConfig();
    });
    this.config.currentLanguage.pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.setURLFromConfig();
    });
    this.broadcaster.on<any>(BroadcastKeys.HIDE_SEARCH_CONTACT_COMPONENT).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.closeSearch();
      });
    });
    this.broadcaster.on<any>(BroadcastKeys.SHOW_HAMBURGER_MENU).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.showUserMenu = true;
      this.changeDetectorRef.markForCheck();
    });
    this.store.select(getRouteId).pipe(takeUntil(this.isAlive$)).subscribe(routeID => {
      this.routeId = routeID;
      this.changeDetectorRef.markForCheck();
    });
    this.activatedRoute.queryParams.pipe(takeUntil(this.isAlive$)).subscribe((params: any) => {
      if (params.searchText && params.searchType === "contact") {
        this.searchKeyword = params.searchText;
      } else {
        if (params.searchType === "contact") {
          this.searchKeyword = "";
        }
      }
      if (params.saveSearch) {
        this.isSavedSearch = false;
      } else {
        if (params.searchType === "contact") {
          this.isSavedSearch = true;
        } else {
          this.isSavedSearch = false;
        }
      }
      this.changeDetectorRef.markForCheck();
    });
    this.broadcaster.on<any>(BroadcastKeys.SAVE_ADVANCE_SEARCH_RESULT).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.openSaveSearchDialog(true);
    });

    this.broadcaster.on<any>("SYNCSEARCHFIELD").pipe(takeUntil(this.isAlive$)).subscribe(res => {
      console.log("syncSearchField: ", res);
      this.searchKeyword = res;
      this.changeDetectorRef.markForCheck();
      this.searchModelChanged.next(res);
    });


    this.searchModelChanged.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(model => {
      this.search();
    });
  }

  getSingleEmail(emails) {
    if (isArray(emails)) {
      return emails[0];
    } else if (emails) {
      return emails;
    }
    return null;
  }

  openLegaldialog(): void {
    console.log("Open Legal Dialog");
  }

  toggleMobileSearch(): void {
    this.searchMode = !this.searchMode;
    if (this.searchMode) {
      setTimeout(() => {
        this.searchTextInput.nativeElement.focus();
      }, 200);
    }
    this.changeDetectorRef.markForCheck();
    this.clearSearchText();
  }

  search(): void {
    this.broadcaster.broadcast("RESET_CONTACT_PLUS_LIST");
    this.store.dispatch(new SetSearchQuery(this.searchKeyword));
    console.log("search", this.router);
    if (!this.searchKeyword) {
      const currentUrl = this.router.url.split("?")[0];
      this.router.navigate([currentUrl]);
      return;
    }
    if (!this.router.url.startsWith("/contactplus/search")) {
      this.router.navigate([this.router.url.split("?")[0]], { queryParams: { searchText: this.searchKeyword } });
    } else {
      this.router.navigate(["/contactplus", "search"], { queryParams: { searchText: this.searchKeyword, searchType: this.searchType } });
    }
  }

  searchOnKeyUp() {
    this.searchKeyDown.next(true);
  }

  openProfileDialog() {
    this.showUserMenu = false;
    this.matDialog.open(AvatarDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact_profile_avatar_dialog"
    });
    this.changeDetectorRef.markForCheck();
  }

  clearSearchText(): void {
    this.searchKeyword = "";
    if (this.searchType === "contact") {
      setTimeout(() => {
        this.router.navigate(["/contactplus"]);
      }, 10);
    }
    this.changeDetectorRef.markForCheck();
  }

  toggleRefineForm() {
    console.log("Open toogle refine form");
  }

  toggleAdvancedSearchForm() {
    console.log("toggle Advance search form");
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  siwtchAppMenuOption() {
    if (this.isMobileScreen) {
      this.showUserMenu = true;
    } else {
      this.switchApp = this.switchApp ? false : true;
    }
    this.changeDetectorRef.markForCheck();
  }

  closeAppAwitcher() {
    this.switchApp = false;
    this.changeDetectorRef.markForCheck();
  }

  updateEmailExpanderPanel() {
    this.emailOptionExpanded = this.emailOptionExpanded ? false : true;
  }


  logout(): void {
    this.showUserMenu = false;
    this.auth.logout();
    this.changeDetectorRef.markForCheck();
  }

  navigatorTo(url: string): void {
    this.router.navigate([url]);
  }


  generalSettings(): void {
    console.log("Open Gernal Setting dialog.");
  }

  changelogDialog(): void {
    console.log("Open Change log dialog");
  }

  aboutDialog(): void {
    this.matDialog.open(AboutDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact_about_dialog"
    });
  }

  mobileAboutDialog(): void {
    this.showUserMenu = false;
    this.matDialog.open(MobileAboutDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact_mobile_about_dialog"
    });
    this.changeDetectorRef.markForCheck();
  }

  openGeneralSettingsDialog(): void {
    this.showUserMenu = false;
    if (!this.isOnline) {
      this.toastService.show("APP_OFFLINE");
      return;
    }
    this.matDialog.open(ContactSettingsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact_settings_dialog"
    });
  }

  openAppSwitcherDialog(): void {
    this.showUserMenu = false;
    this.matDialog.open(AppsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact-app-switch-dialog"
    });
    this.changeDetectorRef.markForCheck();
  }

  changePassword(): void {
    console.log("Change password");
  }

  openChangePasswordDialog(type: string, changePasswordUrl: string): void {
    console.log("Open CHange password dialog.");
  }

  openMobileChangePasswordDialog(type: string, changePasswordUrl: string): void {
    console.log("Open mobile change password dialog");
  }


  searchFocus() {
    this.ActiveSearchBar = true;
  }

  handleSearchTextFieldBlur(event: FocusEvent) {
    setTimeout(() => {
        this.isFocused = false;
    }, 200);
    this.ActiveSearchBar = false;
  }

  showSearchPopover(): void {
    if (this.showAdvancedSearchPopover) {
      this.showAdvancedSearchPopover = false;
    } else {
      this.showAdvancedSearchPopover = true;
    }
    this.changeDetectorRef.markForCheck();
  }

  setSearchFor(value) {

  }

  searchFromText(ev: any): void {
    console.log("searchFromText: ", ev);
    this.broadcaster.broadcast("CLEARSIDEBARSEARCH");
    this.searchModelChanged.next(ev);
  }



  getFirstLastCharacters(user) {
    let characters = user.firstName ? user.firstName.charAt(0) : "";
    characters = characters + (user.lastName ? user.lastName.charAt(0) : "");
    if (characters === "") {
      characters = user.email.charAt(0);
    }
    return characters;
  }

  changeAppearance(): void {
    console.log("Change appearance dialog.");
  }

  processLogout(): void {
    console.log("[header.component] logout");
    if (this.isCordovaOrElectron) {
      this.contactService.cordovaLogout().pipe(take(1)).subscribe();
      localStorage.removeItem("token");
      let initialHref = CommonUtil.isOnAndroid() ? window.location.href.split("/www/")[0] : window.location.href.split("/contactplus")[0];
      window.location.href = `${initialHref}${CommonUtil.isOnAndroid() ? "/www" : ""}/index.html`;
      this.config.loginIframe();
    } else {
      localStorage.removeItem("token");
      this.contactRepository.logout();
    }
  }

  routeToNavigate(url: string): void {
    this.router.navigate([url]);
    this.showUserMenu = false;
    this.changeDetectorRef.markForCheck();
  }

  setURLFromConfig(): void {
    console.log("[URLS]: ", this.URLS);
    let storedLanguage = "en";
    const localStoreLanguage = localStorage.getItem(AppConstants.CONTACT_LANGUAGE);
    if (localStoreLanguage !== null && !!localStoreLanguage && localStoreLanguage !== "") {
      storedLanguage = localStoreLanguage;
    }
    if (this.URLS.serviceDesk) {
      this.serviceDeskURL = this.URLS.serviceDesk;
    } else {
      this.serviceDeskURL = "https://redmine.vnc.biz/helpdesk/incidents/new";
    }
    if (storedLanguage === "en") {
      if (this.config.URLS.faq) {
        this.faqURL = this.config.URLS.faq;
      } else {
        this.faqURL = "https://en.docs.vnc.biz/vnccontacts/faq/";
      }
      if (this.config.URLS.termsOfUseURL) {
        this.termsOfUse = this.config.URLS.termsOfUseURL;
      } else {
        this.termsOfUse = "https://vnclagoon.com/terms";
      }

      if (this.config.URLS.dataPrivacyURL) {
        this.dataPrivacy = this.config.URLS.dataPrivacyURL;
      } else {
        this.dataPrivacy = "https://vnclagoon.com/data-privacy-policy/";
      }
      if (this.config.URLS.imprintURL) {
        this.imprintLink = this.config.URLS.imprintURL;
      } else {
        this.imprintLink = "https://vnclagoon.com/about-vnc/legal-information/";
      }
      if (this.config.URLS.userManual) {
        this.userManual = this.config.URLS.userManual;
      } else {
        this.userManual = "https://en.docs.vnc.biz/vnccontacts/vnccontacts_webclient/";
      }
    } else {
      if (this.config.URLS.faq_de) {
        this.faqURL = this.config.URLS.faq_de;
      } else {
        this.faqURL = "https://de.docs.vnc.biz/vnccontacts/faq/";
      }
      if (this.config.URLS.termsOfUseURLde) {
        this.termsOfUse = this.config.URLS.termsOfUseURLde;
      } else {
        this.termsOfUse = "https://vnclagoon.com/de/nutzungsbedingungen/";
      }
      if (this.config.URLS.dataPrivacyURLde) {
        this.dataPrivacy = this.config.URLS.dataPrivacyURLde;
      } else {
        this.dataPrivacy = "https://vnclagoon.com/de/datenschutzerklaerung/";
      }
      if (this.config.URLS.imprintURLde) {
        this.imprintLink = this.config.URLS.imprintURLde;
      } else {
        this.imprintLink = "https://vnclagoon.com/de/uber-uns/rechtsinformationen/";
      }
      if (this.config.URLS.userManual_de) {
        this.userManual = this.config.URLS.userManual_de;
      } else {
        this.userManual = "https://de.docs.vnc.biz/vnccontacts/vnccontacts_webclient/";
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  openSettingsDialog(): void {
    this.showUserMenu = false;
    if (!this.isOnline) {
      this.toastService.show("APP_OFFLINE");
      return;
    }
    this.matDialog.open(ContactSettingsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact_settings_dialog"
    });
    this.changeDetectorRef.markForCheck();
  }

  setSearchType(type: string): void {
    if (type === "contact") {
      this.searchType = type;
    } else if (type === "contactGroup") {
      this.searchType = type;
    }
    this.store.dispatch(new SetSearchType(type));
    this.changeDetectorRef.markForCheck();
  }

  closeSearch(): void {
    this.searchMode = false;
    this.clearSearchText();
    this.changeDetectorRef.markForCheck();
    console.log("closeSearch()");
    setTimeout(() => {
      this.router.navigate(["/contactplus"]);
    }, 10);
  }

  openListContactDialog(): void {
    this.showUserMenu = false;
    this.matDialog.open(ContactListsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact_lists_dialog"
    });
    this.changeDetectorRef.markForCheck();
  }

  openActionsDialog(): void {
    this.showUserMenu = false;
    this.matDialog.open(MobileActionsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact_mobile_about_dialog"
    });
    this.changeDetectorRef.markForCheck();
  }

  openTagContactDialog(): void {
    this.showUserMenu = false;
    this.matDialog.open(ContactTagsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact_lists_dialog"
    });
    this.changeDetectorRef.markForCheck();
  }

  openSaveSearchesDialog(): void {
    this.showUserMenu = false;
    this.matDialog.open(SaveSearchDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "save_search_dialog"
    });
    this.changeDetectorRef.markForCheck();
  }

  updateContactExpanderPanel() {
    this.contactOptionExpanded = this.contactOptionExpanded ? false : true;
  }

  openContactGroupDialog(): void {
    this.showUserMenu = false;
    this.matDialog.open(ContactGroupDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact-group-dialog"
    });
    this.changeDetectorRef.markForCheck();
  }

  openAdvanceSearchDialog(): void {
    this.contactRepository.openAdvanceSearchDialog();
  }

  saveSearch(): void {
    this.contactRepository.saveSearch();
  }

  openAdvanceSearch(): void {
    const routeId = this.contactRepository.getCurrentRouteId();
    if (routeId !== AppConstants.SEARCH_TYPE) {
      this.searchType = "contact";
      this.changeDetectorRef.markForCheck();
    }
    if (this.searchType === "contact") {
      console.log("[AdvanceSearchComponent]");
      this.matDialog.open(AdvanceSearchComponent, {
        maxWidth: "100%",
        autoFocus: false,
        panelClass: "advance-search-component"
      });
    }
  }

  openSaveSearchDialog(value: boolean): void {
    if (value) {
      this.saveSearchKeyword = "";
    }
    this.isShowSaveSearchDialog = value;
    this.changeDetectorRef.markForCheck();
  }

  saveAdvanceSearch(): void {
    this.openSaveSearchDialog(false);
    let filters: any[] = [];
    this.store.select(getSearchFilters).pipe(take(1)).subscribe(res => {
      filters = res;
    });
    const f: any[] = [];
    const op: any = {};
    const v: any = {};
    const sendQuery: any = {};
    if (!!filters && filters.length > 0) {
      const selectSaveSearchItem = filters;
      console.log("[saveAdvanceSearch][saveAdvanceSearch]: ", selectSaveSearchItem);
      f.push("q");
      op.q = "~";
      v.q = [this.searchKeyword];
      selectSaveSearchItem.map(si => {
          f.push(si.name);
          op[si.name] = si.operatorModel;
          if (!si.showMultiple) {
              if (si.type === "date_past") {
                  v[si.name] = [this.dateFormtter.transform(si.valueSelectModel, "yyyy/MM/dd")];
              } else {
                  v[si.name] = [si.valueSelectModel];
              }
          } else {
              v[si.name] = si.multiValueModel;
          }
      });
    } else {
      f.push("q");
      op.q = "~";
      v.q = [this.searchKeyword];
    }
    sendQuery.f = f;
    sendQuery.op = op;
    sendQuery.v = v;
    sendQuery.query = {
        name: this.saveSearchKeyword
    };
    this.contactRepository.saveAdvanceSearchQuery(sendQuery).subscribe(res => {
      if (res.error) {
          this.toastService.showPlainMessage(res.error[0]);
      } else {
          this.showSavedLbl = true;
          this.changeDetectorRef.markForCheck();
          setTimeout(() => {
              this.showSavedLbl = false;
              this.changeDetectorRef.markForCheck();
          }, 5000);
      }
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  serviceDeskDialog(): void {
    this.matDialog.open(ServicedeskDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact-service-desk-dialog"
    });
  }

  helpFaqDialog(): void {
    this.matDialog.open(HelpFaqDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact-help-faq-dialog"
    });
  }

}
