
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { MatDividerModule } from "@angular/material/divider";
import { MatNativeDateModule } from "@angular/material/core";
import { CdkTreeModule } from "@angular/cdk/tree";
import { ContextMenuModule } from "@perfectmemory/ngx-contextmenu";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PlatformModule } from "@angular/cdk/platform";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { AppsDialogComponent } from "./components/apps-dialog/apps-dialog.component";
import { AppSwitcherComponent } from "./components/app-switcher";
import { FlexLayoutModule } from "@angular/flex-layout";
import { HttpModule } from "@angular/http";
import { HttpClientModule } from "@angular/common/http";
import { UserAvatarComponent } from "./components/user-avtar/user-avatar.component";
import { ContactOperationButtonComponent } from "./components/contact-operation-button/contact-operation-button.component";
import { CustomSpinnerComponent } from "./components/custom-spinner/custom-spinner.component";
import { ContactImageCropperDialogComponent } from "./components/contact-image-cropper-dialog/contact-image-cropper-dialog.component";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { AboutDialogComponent } from "./components/about-dialog/about-dialog.component";
import { MobileAboutDialogComponent } from "./components/mobile-about-dialog/mobile-about-dialog.component";
import { ServicedeskDialogComponent } from "./components/servicedesk-dialog/servicedesk-dialog.component";
import { HelpFaqDialogComponent } from "./components/help-faq-dialog/help-faq-dialog.component";
import { LegalNoticeDialogComponent } from "./components/legal-notice-dialog/legal-notice-dialog.component";
import { ContactSettingsDialogComponent } from "./components/contact-settings-dialog/contact-settings-dialog.component";
import { AvatarDialogComponent } from "./components/avatar-dialog/avatar-dialog.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { VNCAuthenticationComponent } from "./components/vnc-authentication";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatTableModule } from "@angular/material/table";
import { ResizableModule } from "angular-resizable-element";
import { ResizableDirective } from "./directives/resizable.directive";
import { ContactListsDialogComponent } from "./components/contact-lists-dialog/contact-lists-dialog.component";
import { ListsSearchPipe } from "./pipes/search-list.pipe";
import { ContactListsOperationDialogComponent } from "./components/contact-lists-operation-dialog/contact-lists-operation-dialog.component";
import { VNCLongPressDirective } from "./directives/long-press.directive";
import { AddToListsDialogComponent } from "./components/add-to-list-dialog/add-to-list-dialog.component";
import { MobileActionsDialogComponent } from "./components/mobile-actions-dialog/mobile-actions-dialog.component";
import { ContactTagsDialogComponent } from "./components/contacts-tags-dialog/contact-tags-dialog.component";
import { ContactGroupDialogComponent } from "./components/contact-group-dialog/contact-group-dialog.component";
import { MobileContactGroupDetailDialogComponent } from "./components/mobile-contact-group-detail-dialog/mobile-contact-group-detail-dialog.component";
import { SearchContactPipe } from "./pipes/search-contact-list.pipe";
import { AboutDialogMobileComponent } from "./components/about-dialog-mobile/about-dialog-mobile.component";
import { ListContactDialogComponent } from "./components/list-contact-dialog/list-contact-dialog.component";
import { TagContactDialogComponent } from "./components/tag-contact-dialog/tag-contact-dialog.component";
import { AdvanceSearchDialogComponent } from "./components/advance-search-dialog/advance-search-dialog.component";
import { SearchInAppsDialogComponent } from "./components/search-in-apps-dialog/search-in-apps-dialog.component";
import { SearchInListsDialogComponent } from "./components/search-in-lists-dialog/search-in-lists-dialog.component";
import { AddToTagDialogComponent } from "./components/add-to-tag-dialog/add-to-tag-dialog.component";
import { SearchInTagsDialogComponent } from "./components/search-in-tags-dialog/search-in-tags-dialog.component";
import { SaveSearchNameDialogComponent } from "./components/save-search-name-dialog/save-search-name-dialog.component";
import { SaveSearchDialogComponent } from "./components/save-search-dialog/save-search-dialog.component";
import { SaveSearchContactDialogComponent } from "./components/save-search-contact-dialog/save-search-contact-dialog.component";
import { AdvanceSearchComponent } from "./components/advance-search-component/advance-search.component";
import { SearchFilterPipe } from "./pipes/search-filter.pipe";
import { TFASettingsComponent } from "./components/tfa-settings/tfa-settings.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { VNCIconContainerModule } from "../icon-container/icon-container.module";

const ANGULAR_MATERIALS = [
    CdkTreeModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTreeModule,
    ContextMenuModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSortModule,
    FlexLayoutModule,
    MatExpansionModule,
    DragDropModule,
    ScrollingModule,
    MatTableModule
];

const DIALOGUES = [
    AppsDialogComponent,
    AppSwitcherComponent,
    ContactImageCropperDialogComponent,
    ConfirmationDialogComponent,
    AboutDialogComponent,
    MobileAboutDialogComponent,
    ServicedeskDialogComponent,
    HelpFaqDialogComponent,
    LegalNoticeDialogComponent,
    ContactSettingsDialogComponent,
    AvatarDialogComponent,
    ContactListsDialogComponent,
    ContactListsOperationDialogComponent,
    AddToListsDialogComponent,
    MobileActionsDialogComponent,
    ContactTagsDialogComponent,
    AboutDialogMobileComponent,
    AdvanceSearchDialogComponent,
    SearchInAppsDialogComponent,
    SearchInListsDialogComponent,
    ContactGroupDialogComponent,
    MobileContactGroupDetailDialogComponent,
    AboutDialogMobileComponent,
    ListContactDialogComponent,
    TagContactDialogComponent,
    AddToTagDialogComponent,
    SearchInTagsDialogComponent,
    SaveSearchNameDialogComponent,
    SaveSearchDialogComponent,
    SaveSearchContactDialogComponent,
    AdvanceSearchComponent,
    TFASettingsComponent
];

const DIRECTIVES = [
    ResizableDirective,
    VNCLongPressDirective
];

const PIPES = [
    ListsSearchPipe,
    SearchContactPipe,
    SearchFilterPipe
];


@NgModule({
    imports: [
        CommonModule,
        PlatformModule,
        FormsModule,
        TranslateModule,
        HttpClientModule,
        HttpModule,
        ReactiveFormsModule,
        ImageCropperModule,
        ResizableModule,
        ...ANGULAR_MATERIALS,
        VNCIconContainerModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        HttpClientModule,
        HttpModule,
        PageNotFoundComponent,
        AppsDialogComponent,
        AppSwitcherComponent,
        UserAvatarComponent,
        ContactOperationButtonComponent,
        CustomSpinnerComponent,
        ContactImageCropperDialogComponent,
        ConfirmationDialogComponent,
        AboutDialogComponent,
        MobileAboutDialogComponent,
        ServicedeskDialogComponent,
        HelpFaqDialogComponent,
        LegalNoticeDialogComponent,
        ContactSettingsDialogComponent,
        AvatarDialogComponent,
        VNCAuthenticationComponent,
        MobileActionsDialogComponent,
        ContactTagsDialogComponent,
        ResizableModule,
        AddToListsDialogComponent,
        ContactGroupDialogComponent,
        MobileContactGroupDetailDialogComponent,
        AboutDialogMobileComponent,
        ListContactDialogComponent,
        TagContactDialogComponent,
        AdvanceSearchDialogComponent,
        SearchInAppsDialogComponent,
        SearchInListsDialogComponent,
        AddToTagDialogComponent,
        SearchInTagsDialogComponent,
        SaveSearchNameDialogComponent,
        SaveSearchDialogComponent,
        SaveSearchContactDialogComponent,
        AdvanceSearchComponent,
        TFASettingsComponent,
        ...ANGULAR_MATERIALS,
        // Directives
        ...DIRECTIVES,
        // Pipes
        ...PIPES,
        VNCIconContainerModule
    ],
    declarations: [
        PageNotFoundComponent,
        UserAvatarComponent,
        ContactOperationButtonComponent,
        CustomSpinnerComponent,
        AboutDialogComponent,
        MobileAboutDialogComponent,
        ServicedeskDialogComponent,
        HelpFaqDialogComponent,
        LegalNoticeDialogComponent,
        ContactSettingsDialogComponent,
        AvatarDialogComponent,
        VNCAuthenticationComponent,
        ContactListsDialogComponent,
        ContactListsOperationDialogComponent,
        AddToListsDialogComponent,
        MobileActionsDialogComponent,
        ContactTagsDialogComponent,
        AboutDialogMobileComponent,
        ListContactDialogComponent,
        TagContactDialogComponent,
        AdvanceSearchDialogComponent,
        SearchInAppsDialogComponent,
        SearchInListsDialogComponent,
        AddToTagDialogComponent,
        SearchInTagsDialogComponent,
        SaveSearchNameDialogComponent,
        SaveSearchDialogComponent,
        SaveSearchContactDialogComponent,
        AdvanceSearchComponent,
        TFASettingsComponent,
        // Directives
        ...DIRECTIVES,
        // Dialogues
        ...DIALOGUES,
        // Pipes
        ...PIPES,
    ],
    providers: [
        VNCLongPressDirective
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: SharedModule,
            providers: [
            ]
        };
    }
}
